import React, { useState } from "react";

import chevronRight from "../images/Chevron-Right.svg";

const CardFaq = ({text, title}) => {
  const [show, setShow] = useState(false);
  return <>
    <div className={show ? "card-faq-pro is-expanded" : "card-faq-pro"} onClick={() => setShow(!show)}>
      <div data-w-id="e1c83b6e-bb71-b94f-eaa5-63e6d2bd3c79" className="card-faq">
        <div className="text-size-large text-weight-semibold"style={{fontSize: "16px"}}>{title}</div>
        <img src={chevronRight} loading="lazy" alt="" className="icon-seta"/>
      </div>
      <div className="card-faq-wrapper">
        <div className="text-size-large text-color-dark-gray card-faq-content">{text}</div>
      </div>
    </div>
  </>
}

const CommonQuestions = () => {
  return (
    <>
      <div className="section section-faq" style={{backgroundColor: "#fff"}}>
        <div className="page-padding">
          <div className="container-large padding-vertical padding-huge">
            <div className="flex-horizontal justify mobile-flex-vertical">
              <div className="max-width-small">
                <h2 className="heading-large margin-top margin-small">
                  Perguntas Frequentes
                </h2>
                <div className="text-size-large text-color-gray margin-top margin-xsmall">
                  Veja as principais perguntas sobre a Paradigma e seu conteúdo.
                </div>
              </div>
              <div className="max-width-xxlarge">
                <CardFaq
                  title="Quem é a Paradigma?"
                  text="A 1ª plataforma financeira do Brasil especializada em criptomoedas. Empresa orgulhosamente nacional, com um propósito maior: ajudar você e o seu dinheiro a renderem mais nessa nova era. Sem conflito de interesses, sem lero-lero. Numa linguagem que qualquer um consegue entender e pôr em prática."
                />

                <CardFaq
                  title="Por que devo ouvir as ideias dos especialistas da Paradigma?"
                  text="Somos um grupo de cientistas e investidores com mais de 5 anos de experiência nesse setor, e décadas somadas de mercado financeiro. As maiores oportunidades dos últimos tempos passaram pelo nosso radar. Pergunte para qualquer conhecido(a) bitcoiner sobre nossa reputação."
                />

                <CardFaq
                  title="Quanto custa para começar?"
                  text="Você pode experimentar a nossa plataforma gratuitamente. Para acessar todas as suas funcionalidades, cobramos uma assinatura quadrimestral que equivale a R$199,90/mês. Se não ficar completamente satisfeito, você pode cancelar o plano em até 7 dias e receber 100% do seu dinheiro de volta."
                />

                <CardFaq
                  title="Posso cancelar quando quiser?"
                  text="Sim. Não prendemos os nossos clientes. Se assinar e não ficar satisfeito, cancele em até 7 dias para receber 100% do seu dinheiro de volta."
                />

                <CardFaq
                  title="Quanto tempo preciso gastar para tirar proveito?"
                  text="Sugerimos pelo menos 15 minutos por semana para ler nossos conteúdos com calma. Você pode acessá-los de qualquer lugar: de casa, no ônibus, do trabalho, do celular. Nosso boletim semanal resume as ideias mais importantes para o seu patrimônio. Dá pra ler em 5 minutos. Na nossa plataforma, dá pra gastar quanto tempo quiser. Tempo é nosso recurso mais precioso: quanto mais você investir, melhor você vai se sair."
                />

                <CardFaq
                  title="Tenho uma pergunta específica!"
                  text="Não existe 'pergunta boba'. Bobo é não perguntar. Se tiver qualquer dúvida, manda ela por e-mail (perguntas@paradigma.education) ou no nosso Discord. Vai ser um prazer conversar com você 😬"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonQuestions;

