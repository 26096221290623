import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./styles/normalize.css";
import "./styles/webflow.css";
import "./styles/index.css";

import LandingPage from "./pages/LandingPage";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={<LandingPage />}
        />
      </Routes>
    </>
  );
}

export default App;
