import React from "react";

import logoIcon from "../images/logo-paradigma.svg";
import iconMaterials from "../images/icon-materials-32px.svg";
import iconExperts from "../images/icon-experts-32px.svg";
import iconDidatic from "../images/icon-didatic-32px.svg";
import Button from "./Button";

const Header = () => {
  return (
    <>
      <div className="section-navbar section-video-trailer padding-vertical padding-xxsmall">
        <div className="page-padding">
          <div className="container-large">
            <div className="navbar-grid-duo">
              <div
                data-animation="default"
                data-collapse="medium"
                data-duration="400"
                data-easing="ease"
                data-easing2="ease"
                role="banner"
                className="navbar padding-vertical padding-xxsmall w-nav"
              >
                <a 
                  href="https://paradigma.education/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="nav-image-logo w-nav-brand"
                >
                  <img
                    src={logoIcon}
                    loading="lazy"
                    alt=""
                    className="image-logo"
                  />
                </a>  
              </div>
              <div
                id="w-node-c3264975-3374-429e-8820-9bc466de5dcb-66de5db1"
                className="buttons-wrapper"
              >
                <Button
                  id="68837769-e847-d008-7511-91f44f0fba77"
                  namesClass="button-text-white w-inline-block"
                  title="APP"
                  link="https://app.paradigma.education/"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="page-padding">
        <div className="container-large padding-vertical padding-huge ">
          <div className="main-grid page-ranking">
            <div
              id="w-node-_9fe109cd-84dd-4ee2-b0fe-8a75bc8bc482-35152179"
              className="grid-wrapper padding-bottom padding-xlarge section-info-track"
            >
              <div className="container-info-track">
                <div className="landing-tracks-text-primary-xx-large info-track-title">
                  Paradigma Camp
                </div>
                <p className="landing-tracks-text-primary-small info-track-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
              </div>
              <div className="container-buttons">
                <button
                  className="landing-tracks-text-primary-x-small button-iniciar"
                  // onClick={handleClick}
                >
                  Quero participar
                </button>                 
              </div>

            </div>

          </div>

          <div className="main-grid paradigma-video-trailer">
              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconDidatic} alt="icon-didatic" />
                </div>
                <div className="card-title">Didática Inesquecível</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Aprenda de forma simples, prática e inesquecível.
                </p>
              </div>

              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconExperts} alt="icon-experts" />
                </div>
                <div className="card-title">Aprenda com os Melhores</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Aulas exclusivas com os maiores experts do mercado.
                </p>
              </div>

              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconMaterials} alt="icon-materials" />
                </div>
                <div className="card-title">Materiais Complementares</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Além das videoaulas, você também terá ebooks, infográficos e
                  muito mais.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
