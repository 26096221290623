import React, { useState, useEffect } from "react";
// import MetaTags from "react-meta-tags";
import api from "../services/global/api-feargreed";

import Footer from "../components/Footer";
import Discord from "../components/Discord";
import Feedbacks from "../components/Feedbacks";
import Satisfaction from "../components/Satisfaction";
import Header from "../components/Header";
import CommonQuestions from "../components/CommonQuestions";
import Info from "../components/Info";
import Steps from "../components/Steps";
import Form from "../components/Form";

import iconWhatsapp from "../images/icons8-whatsapp.svg";

import "../styles/landing-page.css";

export const LandingVideoTracks = () => {
  const [usuarios, setUsuarios] = useState("8.577");

  useEffect(() => {
    async function getTotalUsuarios() {
      try {
        const response = await api.get("/api/v2/website/users-count");
        setUsuarios(response.data.count);
      } catch (e) {
        console.log(e);
      }
    }
    getTotalUsuarios();
  }, []);

  return (
    <>
      <div className="icon-whatsapp">
        <a 
          href="https://paradigma.education/"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={iconWhatsapp} alt="icon"/>
        </a>
      </div>
      <div className="page-wrapper landing-page-tracks">
        {/* <MetaTags>
          <title>{dataTrail.name}</title>
          <meta name="description" content={dataTrail.description} />
          <meta property="og:title" content={dataTrail.name} />
          <meta property="og:description" content={dataTrail.description} />
        </MetaTags> */}
        <div className="main-wrapper">
          <div
            className="main-wrapper page-video-track"
          >
            <Header />
          </div>

          <Info />
          <Steps />
          <Form />
          <CommonQuestions />
          <Feedbacks usuarios={usuarios} />
          <Discord />
          <Satisfaction />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default LandingVideoTracks;
