import React from "react";

import badgeMoney from "../images/badge-money-back@2x.png";

import "../styles/landing-page.css";

const Satisfaction = () => {
  return <>
    <div className="section-satisfaction">
      <div className="page-padding">
        <div className="container-large padding-xxlarge">
          <div className="main-grid paradigma-satisfaction">
            <div className="satisfaction-badge-money">
              <img src={badgeMoney} alt="badge-money"/>
            </div>

            <div className="container-text-mobile">
              <div className="landing-tracks-text-primary-x-large">Faça um teste sem risco</div>
              <p className="landing-tracks-text-second-medium satisfaction-text" style={{ paddingTop: "16px"}}>Compre o acesso e, caso você não fique satisfeito, em até 7 dias pode pedir 100% do seu dinheiro de volta. Basta mandar um email e nossa equipe vai te reembolsar.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Satisfaction;