import React from "react";

const Message = ({text, success}) => {
  return <>
    <div className="message-container" style={{backgroundColor: success ? "#68ffbf" : "#F8D7DB"}}>
      <span className="message-text" style={{color: success ? "#126C46" : "#842029"}}>{text}</span>
    </div>
  </>
}

export default Message;

// #842029
// #F8D7DB
// #68ffbf
// #126C46