import React from "react";

import Button from "./Button";

import "../styles/landing-page.css";

const Steps = () => {
  return <>
    <div className="section-discord section-satisfaction">
      <div className="page-padding">
        <div className="container-large padding-xxlarge">
          <div className="align-center">
            <div className="landing-tracks-text-primary-x-large discord-title">Passo a passo do Paradigma Camp</div>
            <div className="landing-tracks-text-second-medium discord-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
          </div>
          <div className="margin-top margin-xxlarge">
            <div className="main-grid-step">
              <div className="step-left">
                <div className="landing-tracks-text-primary-large padding-bottom-step">1. Primeiro passo</div>
                <p className="landing-tracks-text-second-small">
                  Contrary to popular belief, 
                  Lorem Ipsum is not simply random text. 
                  It has roots in a piece of classical Latin literature from 45 BC, 
                  making it over 2000 years old.
                </p>
              </div>
            </div>

            <div className="main-grid-step-start">
              <div className="step-right">
                <div className="landing-tracks-text-primary-large padding-bottom-step">2. Segundo passo</div>
                <p className="landing-tracks-text-second-small">
                  Contrary to popular belief, 
                  Lorem Ipsum is not simply random text. 
                  It has roots in a piece of classical Latin literature from 45 BC, 
                  making it over 2000 years old.
                </p>
              </div>
            </div>

            <div className="main-grid-step">
              <div className="step-left">
                <div className="landing-tracks-text-primary-large padding-bottom-step">3. Terceiro passo</div>
                <p className="landing-tracks-text-second-small">
                  Contrary to popular belief, 
                  Lorem Ipsum is not simply random text. 
                  It has roots in a piece of classical Latin literature from 45 BC, 
                  making it over 2000 years old.
                </p>
              </div>
            </div>

            <div className="main-grid-step-start">
              <div className="step-right">
                <div className="landing-tracks-text-primary-large padding-bottom-step">4. Quarto passo</div>
                <p className="landing-tracks-text-second-small">
                  Contrary to popular belief, 
                  Lorem Ipsum is not simply random text. 
                  It has roots in a piece of classical Latin literature from 45 BC, 
                  making it over 2000 years old.
                </p>
              </div>
            </div>
          </div>

          <div className="buttons-wrapper-step margin-top margin-medium">
            <Button
              id="cdfec08f-3ae4-19b4-aac3-3fef37e7c041"
              namesClass="button-large w-inline-block"
              title="Participe agora"
            />
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Steps;