import React from "react";

import "../styles/landing-page.css";

const Info = () => {
  return <>
    <div className="section-discord">
      <div className="page-padding">
        <div className="container-large padding-xxlarge">
          <div className="align-center">
            <div className="landing-tracks-text-primary-x-large discord-title">Saiba como a Paradigma Camp vai te ajudar</div>
            <div className="landing-tracks-text-second-medium discord-text">Vamos te fazer aproveitar da verdade o mercado cripto.</div>
          </div>

          <div className="margin-top margin-xxlarge" style={{height: "400px", backgroundColor: "#1e1e2f", }}></div>
          
        </div>
      </div>
    </div>
  </>
}

export default Info;